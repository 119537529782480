<template>
  <div id="app" style="margin-top: 0;" class="d-flex flex-column min-vh-100">
    <!--<img alt="Vue logo" src="./assets/logo.png">-->
    
      

    <b-navbar type="dark" variant="primary">
      <b-navbar-brand href="#">
        <img src="https://ceesa.com/images/logo-ceesa-gris.png" class="d-inline-block align-middle" alt="Kitten">
        <span class="abs-center-x" style="font-weight: 600;">PAGO DE RECIBOS ONLINE</span>
      </b-navbar-brand>

    </b-navbar>
    
    <b-container class="bv-example-row">
      <b-row class="text-left mt-5">
        <b-col></b-col>
        <b-col cols="10" style="font-size: large;">Desde esta página puede realizar el pago online del recibo y factura que detallamos a continuación. Cuando pulse el botón aceptar será redirigido a una pasarela de pago bancaria desde la que podrá realizar su transacción de forma totalmente segura.</b-col>
        <b-col></b-col>
      </b-row>
      
      
      <b-row class="text-left mt-4">
        <b-col></b-col>
        <b-col cols="10"><h4>Recibo: {{recibo}}</h4></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="text-left">
        <b-col></b-col>
        <b-col cols="10"><h4>Factura: {{factura}}</h4></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="text-left">
        <b-col></b-col>
        <b-col cols="10"><h4>Fecha: {{fecha}}</h4></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="text-left">
        <b-col></b-col>
        <b-col cols="10"><h4>Importe: {{importe}}</h4></b-col>
        <b-col></b-col>
      </b-row>
      
      <b-row class="text-left" style="margin-top:20px;">
        <b-col></b-col>
        <b-col cols="10">
          <b-form-checkbox switch size="lg" v-model="recurrente">Autorizo futuros pagos con la misma tarjeta de forma automática.</b-form-checkbox>
        </b-col>
        <b-col></b-col>
      </b-row>
      
      <b-row class="text-center">
        <b-col></b-col>
        <b-col cols="10"><b-button variant="outline-primary" @click="pasarela" style="margin-top:40px;"><b-icon-credit-card2-front ></b-icon-credit-card2-front><span style="padding-left:7px; font-weight:500;">Pago Seguro</span></b-button></b-col>
        <b-col></b-col>
      </b-row>
      
      
    
    </b-container>
    
    <footer class="mt-auto">
      <p class="text-center">Copyright &copy; {{anio}}, CEESA, S.A.
        <b-link v-b-modal.legal variant="primary" style="padding-left:15px;">Aviso Legal</b-link>
        <b-link v-b-modal.cookies variant="primary" style="padding-left:15px;">Cookies</b-link>
        <b-link v-b-modal.terminos variant="primary" style="padding-left:15px;">Términos y Condiciones</b-link>
      </p>
    </footer>
    
    <b-modal id="legal" size="xl" title="AVISO LEGAL" ok-title="de acuerdo" :ok-only="true">
      <h5>DATOS IDENTIFICATIVOS DEL TITULAR DEL SITIO WEB</h5>
      <p style="text-align: justify;"> En cumplimiento del deber de información estipulado en el artículo 10 de la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y de Comercio Electrónico, <b>CEESA, S.A.</b> en calidad de titular de los sitios web <b>www.tushoras.es</b> <b>www.erpgestion.es</b> y <b>www.ceesa.com</b>, procede a comunicarles los datos identificativos exigidos por la referida norma:</p>
      
      <p style="text-align: justify;">
          Denominación social: <b>CEESA, S.A.</b>
          <br> Domicilio social: Muelle T. Olabarri, 2 bajo. 48930 - Getxo (Vizcaya)
          <br> CIF: A48201750
          <br> Dirección de correo electrónico: correo@ceesa.com
          <br> Inscrita en el Registro Mercantil de Vizcaya, Tomo 1.771, Libro 0, Folio 46, Hoja 11.535</p>
      
      <p style="text-align: justify;">La presente información conforma y regula las condiciones de uso, las limitaciones de responsabilidad y las obligaciones que, los usuarios de la página Web que se publica bajo el nombre de dominio <b>www.erpgestion.es</b> y <b>www.ceesa.com</b>, asumen y se comprometen a respetar.</p>
      
      <h5>CONDICIONES DE USO</h5>
      <p style="text-align: justify;">La simple y mera utilización de la Página otorga la condición de usuario de la Página, bien sea persona física o jurídica, y obligatoriamente implica la aceptación completa, plena y sin reservas de todas y cada una de las cláusulas y condiciones generales incluidas en el Aviso Legal. Si el Usuario no estuviera conforme con las cláusulas y condiciones de uso de este Aviso Legal, se abstendrá de utilizar la Página. Este Aviso Legal está sujeto a cambios y actualizaciones por lo que la versión publicada por <b>CEESA, S.A.</b> puede ser diferente en cada momento en que el Usuario acceda al Portal. Por tanto, el Usuario debe leer el Aviso Legal en todas y cada una de las ocasiones en que acceda a la Página.</p>
      <p style="text-align: justify;">A través de la Página, <b>CEESA, S.A.</b> facilita a los Usuarios el acceso y la utilización de diversos Contenidos publicados por medio de Internet por <b>CEESA, S.A.</b> o por terceros autorizados.<br>El Usuario esta obligado y se compromete a utilizar la Página y los Contenidos de conformidad con la legislación vigente, el Aviso Legal, y cualquier otro aviso o instrucciones puestos en su conocimiento, bien sea por medio de este aviso legal o en cualquier otro lugar dentro de los Contenidos que conforman la Página, así como con las normas de convivencia, la moral y buenas costumbres generalmente aceptadas. A tal efecto, el Usuario se obliga y compromete a NO utilizar cualquiera de los Contenidos con fines o efectos ilícitos, prohibidos en el Aviso Legal o por la legislación vigente, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de los Contenidos, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático propios o contratados por <b>CEESA, S.A.</b>, de otros Usuarios o de cualquier usuario de Internet (hardware y software).<br> El Usuario se obliga y se compromete a no transmitir, difundir o poner a disposición de terceros cualquier clase de material contenido en la Página, tales como informaciones, textos, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software, logotipos, marcas, iconos, tecnología, fotografías, software, enlaces, diseño gráfico y códigos fuente, o cualquier otro material al que tuviera acceso en su condición de Usuario de la Página, sin que esta enumeración tenga carácter limitativo. Asimismo, de conformidad con todo ello, el Usuario no podrá:<br>
      <ul class="arrow" style="text-align: justify; padding-left: 50px;">
          <li>Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los Contenidos, a menos que se cuente con la autorización escrita y explícita de <b>CEESA, S.A.</b>, que es titular de los correspondientes derechos, o bien que ello resulte legalmente permitido.</li>
          <li>Suprimir, manipular o de cualquier forma alterar el “copyright” y demás datos identificativos de la reserva de derechos de <b>CEESA, S.A.</b> o de sus titulares, de las huellas y/o identificadores digitales, o de cualesquiera otros medios técnicos establecidos para su reconocimiento.</li>
      </ul>
      </p>
      <p style="text-align: justify;">El Usuario deberá abstenerse de obtener e incluso de intentar obtener los Contenidos empleando para ello medios o procedimientos distintos de los que, según los casos, se hayan puesto a su disposición a este efecto o se hayan indicado a este efecto en las páginas Web donde se encuentren los Contenidos o, en general, de los que se empleen habitualmente en Internet a este efecto siempre que no entrañen un riesgo de daño o inutilización de la Página, y/o de los Contenidos.</p>
      <h5>PROPIEDAD INTELECTUAL</h5>
      <p style="text-align: justify;">Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la Página son propiedad de <b>CEESA, S.A.</b> o, en su caso, de terceros que han autorizado su uso, sin que pueda entenderse que el uso o acceso al Portal y/o a los Contenidos atribuya al Usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos, y sin que puedan entenderse cedidos al Usuario, ninguno de los derechos de explotación que existen o puedan existir sobre dichos Contenidos. De igual modo los Contenidos son propiedad intelectual de <b>CEESA, S.A.</b>, o de terceros en su caso, por tanto, los derechos de Propiedad Intelectual son titularidad de <b>CEESA, S.A.</b>, o de terceros que han autorizado su uso, a quienes corresponde el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación. La utilización no autorizada de la información contenida en esta Web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de <b>CEESA, S.A.</b> o de terceros incluidos en la Página que hayan cedido contenidos dará lugar a las responsabilidades legalmente establecidas.</p>
      <h5>HIPERENLACES</h5>
      <p style="text-align: justify;">Aquellas personas que se propongan establecer hiperenlaces entre su página Web y la Página deberán observar y cumplir las condiciones siguientes:
          <ul class="arrow" style="text-align: justify; padding-left: 50px;">
              <li>No será necesaria autorización previa cuando el Hiperenlace permita únicamente el acceso a la página de inicio, pero no podrá reproducirla de ninguna forma. Cualquier otra forma de Hiperenlace requerirá la autorización expresa e inequívoca por escrito por parte de <b>CEESA, S.A.</b></li>
              <li>No se crearán “marcos” (“frames”) con las páginas Web ni sobre las páginas Web de <b>CEESA, S.A.</b></li>
              <li>No se realizarán manifestaciones o indicaciones falsas, inexactas, u ofensivas sobre <b>CEESA, S.A.</b> sus directivos, sus empleados o colaboradores, o de las personas que se relacionen en la Página por cualquier motivo, o de los Usuarios de las Página, o de los Contenidos suministrados.</li>
              <li>No se declarará ni se dará a entender que <b>CEESA, S.A.</b> ha autorizado el Hiperenlace o que ha supervisado o asumido de cualquier forma los Contenidos ofrecidos o puestos a disposición de la página Web en la que se establece el Hiperenlace.</li>
              <li>La página Web en la que se establezca el Hiperenlace solo podrá contener lo estrictamente necesario para identificar el destino del Hiperenlace.</li>
              <li>La página Web en la que se establezca el Hiperenlace no contendrá informaciones o contenidos ilícitos, contrarios a la moral y a las buenas costumbres generalmente aceptadas y al orden público, así como tampoco contendrá contenidos contrarios a cualesquiera derechos de terceros.</li>
          </ul>
      </p>
      <h5>DISPONIBILIDAD DE LA PÁGINA</h5>
      <p style="text-align: justify;"><b>CEESA, S.A.</b> no garantiza la inexistencia de interrupciones o errores en el acceso a la Página, a sus Contenidos, ni que éste se encuentren actualizados, aunque desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos. Por consiguiente, <b>CEESA, S.A.</b> no se responsabiliza de los daños o perjuicios de cualquier tipo producidos en el Usuario que traigan causa de fallos o desconexiones en las redes de telecomunicaciones que produzcan la suspensión, cancelación o interrupción del servicio del Portal durante la prestación del mismo o con carácter previo.
          <br>
          <b>CEESA, S.A.</b> excluye, con las excepciones contempladas en la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad, continuidad o calidad del funcionamiento de la Página y de los Contenidos, al no cumplimiento de la expectativa de utilidad que los usuarios hubieren podido atribuir a la Página y a los Contenidos.
          <br> La función de los Hiperenlaces que aparecen en esta Web es exclusivamente la de informar al usuario acerca de la existencia de otras Web que contienen información sobre la materia. Dichos Hiperenlaces no constituyen sugerencia ni recomendación alguna.
          <br>
          <b>CEESA, S.A.</b> no se hace responsable de los contenidos de dichas páginas enlazadas, del funcionamiento o utilidad de los Hiperenlaces ni del resultado de dichos enlaces, ni garantiza la ausencia de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software), los documentos o los ficheros del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo.
          <br> El acceso a la Página no implica la obligación por parte de <b>CEESA, S.A.</b> de controlar la ausencia de virus, gusanos o cualquier otro elemento informático dañino. Corresponde al Usuario, en todo caso, la disponibilidad de herramientas adecuadas para la detección y desinfección de programas informáticos dañinos, por lo tanto, <b>CEESA, S.A.</b> no se hace responsable de los posibles errores de seguridad que se puedan producir durante la prestación del servicio de la Página, ni de los posibles daños que puedan causarse al sistema informático del usuario o de terceros (hardware y software), los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario utilizado para la conexión a los servicios y contenidos de la Web, de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.</p>
      <h5>CALIDAD DE LA PÁGINA</h5>
      <p style="text-align: justify;">Dado el entorno dinámico y cambiante de la información y servicios que se suministran por medio de la Página, <b>CEESA, S.A.</b> realiza su mejor esfuerzo, pero no garantiza la completa veracidad, exactitud, fiabilidad, utilidad y/o actualidad de los Contenidos. La información contenida en las páginas que componen este Portal sólo tiene carácter informativo, consultivo, divulgativo y publicitario. En ningún caso ofrecen ni tienen carácter de compromiso vinculante o contractual.</p>
      <h5>LIMITACIÓN DE RESPONSABILIDAD</h5>
      <p style="text-align: justify;"><b>CEESA, S.A.</b> excluye toda responsabilidad por las decisiones que el Usuario pueda tomar basado en esta información, así como por los posibles errores tipográficos que puedan contener los documentos y gráficos de la Página. La información está sometida a posibles cambios periódicos sin previo aviso de su contenido por ampliación, mejora, corrección o actualización de los Contenidos.</p>
      <h5>CERTIFICADO SLL (SECURE SOCKETS LAYER)</h5>
      <p style="text-align: justify;"> El <b>CERTIFICADO SSL</b> proporciona autenticación, privacidad y seguridad de la información entre <b>CEESA, S.A.</b> y el usuario. 
      <br><b>CEESA SA</b> dispone de un certificado de seguridad que se utiliza por <b>CERTIFICADO SSL</b> para realizar conexiones seguras. 
      <br>En este proceso se establecen varios parámetros para realizar la conexión de forma segura y se establece usando llaves preestablecidas, codificando y descodificando todos los datos enviados hasta que la conexión sea cerrada.</p>
      <h5>NOTIFICACIONES</h5>
      <p style="text-align: justify;">Todas las notificaciones y comunicaciones por parte de <b>CEESA, S.A.</b> al Usuario realizados por cualquier medio se considerarán eficaces a todos los efectos.</p>
      <h5>DISPONIBILIDAD DE LOS CONTENIDOS</h5>
      <p style="text-align: justify;">La prestación del servicio de la Página y de los Contenidos tiene, en principio, duración indefinida. <b>CEESA, S.A.</b>, no obstante, queda autorizada para dar por terminada o suspender la prestación del servicio de la Página y/o de cualquiera de los Contenidos en cualquier momento. Cuando ello sea razonablemente posible, <b>CEESA, S.A.</b> advertirá previamente la terminación o suspensión de la Página.</p>
      <h5>PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h5>
      <h5>POLÍTICA DE PRIVACIDAD</h5>
      <p style="text-align: justify;"><b>CEESA, S.A.</b> es consciente de la importancia de la privacidad de los datos de carácter personal y por ello, ha implementado una política de tratamiento de datos orientada a proveer la máxima seguridad en el uso y recogida de los mismos, garantizando el cumplimiento de la normativa vigente en la materia y configurando dicha política como uno de los pilares básicos en las líneas de actuación de la entidad. Durante la navegación a través de la web tu nombre de dominio es posible se que soliciten datos de carácter personal a través de diferentes formularios dispuestos al efecto. Dichos datos formarán parte de los pertinentes ficheros en función de la finalidad determinada y concreta que motiva el recabo de los mismos. De esta forma, la información particular de cada tratamiento de datos se aportará junto a cada formulario web, siendo común a todos ellos el responsable del fichero: <b>CEESA, S.A.</b>, domiciliada en tu domicilio social, así como el lugar y forma de ejercicio de los derechos de acceso, rectificación, cancelación y oposición, que deberá formalizarse mediante una comunicación escrita a la dirección indicada anteriormente incluyendo copia del DNI o documento identificativo equivalente. En el supuesto de que aporte sus datos a través de un mensaje de correo electrónico, el mismo formará parte de un fichero cuya finalidad será la gestión de la solicitud o comentario que nos realiza, siendo aplicables el resto de extremos indicados en el párrafo anterior.
          <br> Asimismo, las condiciones generales de contratación de los servicios de <b>CEESA, S.A.</b>, contienen las características y naturaleza del tratamiento de los datos que serán desarrollados por la misma en el supuesto de que contrate cualquiera de ellos. Por otro lado, <b>CEESA, S.A.</b> ha implantado las medidas técnicas y organizativas necesarias para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los Datos Personales que los interesados pudieran facilitar como consecuencia del acceso a las diferentes secciones del website tu nombre de dominio, aplicando las medidas de seguridad contempladas en el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>
      <h5>JURISDICCIÓN</h5>
      <p style="text-align: justify;">Para cuantas cuestiones se susciten sobre la interpretación, aplicación y cumplimiento de este Aviso Legal, así como de las reclamaciones que puedan derivarse de su uso, todos las partes intervinientes se someten a los Jueces y Tribunales de Getxo, renunciando de forma expresa a cualquier otro fuero que pudiera corresponderles. Legislación aplicable El Aviso Legal se rige por la ley española. Copyright © {{anio}} – <b>CEESA, S.A.</b>
          <br> Reservados todos los derechos de autor por las leyes y tratados internacionales de propiedad intelectual. Queda expresamente prohibida su copia, reproducción o difusión, total o parcial, por cualquier medio.</p>
    </b-modal>
    
    <b-modal id="cookies" size="xl" title="POLÍTICA DE COOKIES" ok-title="de acuerdo" :ok-only="true">
      <h5>¿Que es una COOKIE?</h5>
      <div style="text-align: justify;">
          <p>Una Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contenga y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
          <p>Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y usabilidad de nuestra web. Utilizamos cookies para mejorar el servicio que prestamos como las cookies de sesión y las cookies persistentes que, a su vez, pueden ser cookies propias o de terceros.</p>
          <p>Las cookies de sesión son cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión. Las cookies persistentes son un tipo de cookies en el que los datos siguen almacenados en el terminal y a los que se puede acceder y tratar durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años. Las cookies persistentes de nuestra página web pueden ser:</p>
          <br><div style="font-size: 15; font-weight: bold;">PROPIAS</div>
          <p>Se utilizan para el sistema de gestión de pedidos, almacenando solo información temporal.</p>
          <div style="font-size: 15; font-weight: bold;">DE TERCEROS</div>
          <ul style="list-style: square inside; padding-left: 10px;">
              <li><b>Cookies de análisis:</b> Son aquéllas que permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos. Se utilizan cookies de Google Analytics que recopilan información anónima sobre la navegación de los usuarios por el sitio web con el fin de conocer el origen de las visitas y otros datos estadísticos similares. Más información en: https://developers.google.com/analytics/devguides/collection/analyticsjs.</li>
          </ul>
          <br><h5>Desactivación y eliminación de cookies</h5>
          <p>Puede deshabilitar las cookies mediante la configuración del explorador que le permite gestionar, en cualquier momento, el establecimiento de cookies en su navegador, en su totalidad o en parte, según su criterio.</p>
          <p>El usuario debe tener en cuenta que algunas características de los contenidos de la página web de <b><i>tushoras.es</i></b> solo están disponibles si se permite la instalación de cookies en su navegador. Sin embargo, si utiliza la configuración del explorador para bloquear todas las cookies, es posible que no pueda acceder total o parcialmente al Sitio. Usted podrá retirar el consentimiento inicialmente prestado en cualquier momento.</p>
      
      </div>
      
    </b-modal>
    
    <b-modal id="terminos" size="xl" title="TÉRMINOS Y CONDICIONES DE COMPRA" ok-title="de acuerdo" :ok-only="true">
      <div style="text-align: justify;">
        <p>Esta es una pasarela web que permite realizar pagos de recibos online correspondientes a facturas emitidas por aplicaciones desarrolladas por CEESA, S.A.</p>
        <h5>Forma de pago</h5>
        <p>El pago sólo podrá realizarse a través de tarjeta de crédito o débito. Como medida de seguridad se encriptarán sus datos antes de que se realice la transferencia de información desde su ordenador o dispositivo móvil al sistema de pago seguro.</p>
        <p>El usuario debe introducir el número de tarjeta, fecha de caducidad y titular de la misma, además del CVV (código de tres dígitos, las tres últimas cifras en la parte posterior de la tarjeta). Recibido el pago <b><i>CEESA, S.A.</i></b> enviará por correo electrónico una notificación confirmando la compra mediante un número de pedido, con el detalle del mismo.</p>
        <br><h5>Precios</h5>
        <p>Todos los productos indican el precio de venta en Euros e incluyen el Impuesto sobre el Valor Añadido (IVA). Los precios de los productos pueden cambiar en cualquier momento, pero los posibles cambios, no afectarán a las compras ya confirmadas.</p>
        <br><h5>Desestimiento</h5>
        <p>Realizada la compra y autorizado el pago de la misma, no podrá ser cancelada ya que se trata de un servicio digital. Las reclamaciones en relación a la calidad del mismo serán atendidos por <b><i>CEESA, S.A.</i></b> a través de <a href="mailto:correo@ceesa.com">correo@ceesa.com</a>.</p>
      
      </div>
      
    </b-modal>
    
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
      
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
const RUTA_PASARELA = "https://pagos.ceesa.com"
const RUTA_PASARELA_BANCO = "https://sis-t.redsys.es:25443/sis/realizarPago"  // modo pruebas
//const RUTA_PASARELA_BANCO = "https://sis-t.redsys.es:25443/sis/realizarPago"  // modo producción  TUS_HORAS
const axios = require('axios')

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  data() {
    return {
      recibo: '',
      factura: '',
      fecha: '',
      importe: '',
      importepas: '',
      anio: '',
      recurrente: false,
      email: ''
    }
  },
  methods: {
    pasarela() {
      var ord2 = getRandomInt(100,999).toString(36)+getId()  // hasta 12 dígitos alfanuméricos
      var oRef = {
        "order": ord2,
        "recibo": this.recibo,
        "factura": this.factura,
        "fecha": this.fecha,
        "importe": this.importe,
        "importepas": this.importepas,
        "email": this.email
      }
      var merData = JSON.stringify(oRef)
      var params = new URLSearchParams()
      if (this.recurrente==true) {
        // GENERAMOS EL PAGO AUTORIZANDO FUTUROS PAGOS RECURRENTES
        console.log("pago recurrente")
        console.log("oRef: ",oRef)
        console.log("merdata: ",merData)
        params.append('merdata', merData)
        axios.post(RUTA_PASARELA+'/php/pasarela_pago_autoriz.php', params)
        .then((respuesta) => {
          // Crea formulario
          var mapForm = document.createElement("form");
          //mapForm.target = "_blank"; 
          mapForm.target = "_self";   
          mapForm.method = "POST";
          mapForm.action = RUTA_PASARELA_BANCO;
          // Crea un input
          var mapInput = document.createElement("input");
          mapInput.type = "hidden";
          mapInput.name = "Ds_SignatureVersion";
          mapInput.value = "HMAC_SHA256_V1";
          // Añade el input al formulario
          mapForm.appendChild(mapInput);
          // Crea un input
          var mapInput2 = document.createElement("input");
          mapInput2.type = "hidden";
          mapInput2.name = "Ds_MerchantParameters";
          mapInput2.value = respuesta.data.params;
          // Añade el input al formulario
          mapForm.appendChild(mapInput2);
          // Crea un input
          var mapInput3 = document.createElement("input");
          mapInput3.type = "hidden";
          mapInput3.name = "Ds_Signature";
          mapInput3.value = respuesta.data.signature;
          // Añade el input al formulario
          mapForm.appendChild(mapInput3);
          // Añade el formulario al dom
          document.body.appendChild(mapForm);
          // Hace el submit del formulario
          mapForm.submit();
        })
        .catch((error) => {
           console.log('error: ',error)
        });
      } else {
        // GENERAMOS SOLO EL PAGO SIN AUTORIZACION DE PAGOS RECURRENTES
        console.log("oRef: ",oRef)
        console.log("mersata: ",merData)
        params.append('merdata', merData)
        axios.post(RUTA_PASARELA+'/php/pasarela_pago.php', params)
        .then((respuesta) => {
          // Crea formulario
          var mapForm = document.createElement("form");
          //mapForm.target = "_blank"; 
          mapForm.target = "_self";   
          mapForm.method = "POST";
          mapForm.action = RUTA_PASARELA_BANCO;
          // Crea un input
          var mapInput = document.createElement("input");
          mapInput.type = "hidden";
          mapInput.name = "Ds_SignatureVersion";
          mapInput.value = "HMAC_SHA256_V1";
          // Añade el input al formulario
          mapForm.appendChild(mapInput);
          // Crea un input
          var mapInput2 = document.createElement("input");
          mapInput2.type = "hidden";
          mapInput2.name = "Ds_MerchantParameters";
          mapInput2.value = respuesta.data.params;
          // Añade el input al formulario
          mapForm.appendChild(mapInput2);
          // Crea un input
          var mapInput3 = document.createElement("input");
          mapInput3.type = "hidden";
          mapInput3.name = "Ds_Signature";
          mapInput3.value = respuesta.data.signature;
          // Añade el input al formulario
          mapForm.appendChild(mapInput3);
          // Añade el formulario al dom
          document.body.appendChild(mapForm);
          // Hace el submit del formulario
          mapForm.submit();
        })
        .catch((error) => {
           console.log('error: ',error)
        });
        
      }
      console.log(miorden())
      console.log(getId())
      console.log(getRandomInt(5, 10))
      
    }
  },
  mounted() {
    var currentTime = new Date();
    this.anio = currentTime.getFullYear()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.has('recibo')) {
      this.recibo = urlParams.get('recibo')
    }
    if (urlParams.has('factura')) {
      this.factura = urlParams.get('factura')
    }
    if (urlParams.has('fecha')) {
      this.fecha = urlParams.get('fecha')
    }
    if (urlParams.has('importe')) {
      this.importe = urlParams.get('importe')
      var importe2 = this.importe.replace("€","")
      importe2 = importe2.replace(",","")
      this.importepas = importe2
      console.log(importe2)
    }
    if (urlParams.has('email')) {
      this.email = urlParams.get('email')
    }
  }
}

function miorden() {
  var fecha = new Date()
  var ano = fecha.getFullYear()
  var mes = fecha.getMonth()+1
  var dia = fecha.getDate()
  var h = fecha.getHours()
  var m = fecha.getMinutes()
  var s = fecha.getSeconds()
  var o = ano.toString().substring(2,4)+mes.toString()+dia.toString()+h.toString()+m.toString()+s.toString()
  return o
}

function getId() {
  //var newTime = parseInt(moment().format("X")) - 1546300800;//seconds since 01/01/2019
  var newTime = Math.floor((new Date()).getTime() / 1000) - 1546300800;//seconds since 01/01/2019
  return newTime.toString(36);
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  
}
.abs-center-x {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
}
</style>
